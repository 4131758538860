import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/mobile/_templates/chapter_4/sub_8/slide1';

const Slide = () => {
  const query = useStaticQuery(graphql`
  query {
    mdx(frontmatter: {language: {eq: "RU"}, title: {eq: "slide-4-8-1"}}) {
      body
    }
    audioImage: file(relativePath: { eq: "chapter_4/sub_8/4-8-1-audio.png"}) {
      childImageSharp {
          fluid(maxWidth: 275) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
    },
    file(relativePath: { eq: "chapter_4/sub_8/mobile/4-8-1-bg.jpg"}) {
      childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
    }
  }
  `);
  return (
    <Template query={query} title="Часть VIII" audio='Юзеф Веробей' />
  );
};


export default Slide;
